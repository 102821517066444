<template>
  <div class='relative'>
    <input class='form-input' type='text' placeholder='종목코드 / 종목명' v-model='searchText' @keyup.enter='refreshSearch' />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ListingSearch',
  props: ['searchName'],
  data () {
    return {
      searchText: '',
    }
  },
  methods: {
    ...mapActions('listings', [
      'getListings',
    ]),
    refreshSearch () {
      this.getListings({ search_text: this.searchText })
    }
  },
  mounted () {
    this.searchText = this.searchName
  }
}
</script>
